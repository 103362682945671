import styled from 'styled-components';

import T from './Typography';

const StyledSpinner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;

  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #a614ce;
    animation: spin 1s infinite linear;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface SpinnerProps {
  message?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ message }) => (
  <StyledSpinner>
    <div className="spinner"></div>
    {message && (
      <T.Body style={{ marginTop: '1rem' }} textBlack>
        {message}
      </T.Body>
    )}
  </StyledSpinner>
);

export default Spinner;
