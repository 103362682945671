import { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import styled from 'styled-components';
import Link from 'next/link';

import { useJune } from '../lib/hooks';
import { magic } from '../lib/magic';
import { UserContext } from '../lib/UserContext';

import Layout from '../components/Layout';
import T from '../components/Typography';
import Spinner from '../components/Spinner';

const S = {};
S.StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  margin: 0 auto;
  margin-top: 32px;
  border-radius: ${props => props.theme.borderRadius};
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  padding: 1.5rem;

  h1 {
    font-size: 24px;
    margin: 0px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
  }

  #instructions {
    font-family: 'Inter', sans-serif;
    padding: 16px;
  }

  @media only screen and (min-width: 768px) {
    width: 600px;
    padding: 2rem;

    #instructions {
      padding: 24px;
    }
  }
`;

S.LoginMethodsContainer = styled.div`
  ${'' /* border: 1px solid rgba(0, 0, 0, 0.1); */}
  ${'' /* border-radius: 0.5rem; */}
  ${'' /* padding: 1rem; */}
  width: auto;
  margin: 2rem auto;

  @media only screen and (min-width: 768px) {
    ${'' /* padding: 1rem; */}
    width: 90%;
  }
`;

S.GoogleLoginButton = styled.button`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  width: 100%;
  font-size: 0.875rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
    cursor: pointer;
  }
`;

S.EmailLoginContainer = styled.div`
  label {
    font-size: 0.875rem;
    color: black;
    display: block;
  }

  input {
    box-sizing: border-box;
    padding: 0.5rem;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  button {
    font-size: 0.875rem;
    background: #ffe76b;
    border-radius: 0.5rem;
    width: 100%;
    border: none;
    padding: 0.5rem;
    box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.25);

    &:hover {
      cursor: pointer;
      background-color: #00ffff;
      transition: background-color 600ms;
    }
  }
`;

S.InstructionItems = styled.ol`
  padding-left: 24px;
`;

S.InstructionItem = styled.li`
  color: #333;
  margin-bottom: 8px;
  font-family: Roboto, sans-serif;
`;

S.HorizontalRule = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: gray;

  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: lightgray;
  }

  &::before {
    margin-right: 8px;
  }

  &::after {
    margin-left: 8px;
  }
`;

const Login = () => {
  const router = useRouter();
  const analytics = useJune(process.env.NEXT_PUBLIC_JUNE_WRITE_KEY);

  const { login, user, userLoading } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    if (user) {
      if (analytics) {
        analytics.identify(user.issuer, {
          email: user.email,
        });
      }
      router.push('/d');
    } else if (!userLoading && user === null) {
      setIsLoggingIn(false);
    }
  }, [router, user, userLoading, analytics]);

  const handleSocialLogin = async () => {
    setIsLoggingIn(true);
    try {
      await magic.oauth.loginWithRedirect({
        provider: 'google',
        redirectURI: new URL('/callback', window.location.origin).href,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogin = async e => {
    e.preventDefault();
    login({ email });
  };

  return (
    <>
      <Layout>
        <Head>
          <title>Get Started | Minicastle</title>
          <meta
            name="description"
            content="Whether you're new to the crew or a returning member, get started with Minicastle here just by entering your email."
          ></meta>
        </Head>
        <S.StyledContainer>
          <h1>
            Sign Up
            <span
              style={{
                margin: '0rem 0.5rem',
                color: 'rgba(0,0,0,.3)',
              }}
            >
              /
            </span>
            Log In
          </h1>
          {userLoading || user ? (
            <Spinner message="Logging you in..." />
          ) : (
            <>
              <S.LoginMethodsContainer>
                <S.EmailLoginContainer id="auth-form">
                  <form>
                    <T.Label htmlFor="">Enter your email address</T.Label>
                    <input
                      type="email"
                      name="email"
                      required
                      placeholder="you@email.com"
                      onChange={e => setEmail(e.target.value)}
                      disabled={isLoggingIn}
                    />
                    {error && (
                      <p style={{ color: 'red', fontFamily: 'sans-serif' }}>
                        {error.message}
                      </p>
                    )}
                    <button onClick={handleLogin} disabled={isLoggingIn}>
                      Continue with Email Address
                    </button>
                  </form>
                </S.EmailLoginContainer>
                <S.HorizontalRule>
                  <span className="divider-text">or</span>
                </S.HorizontalRule>
                <S.GoogleLoginButton
                  onClick={handleSocialLogin}
                  disabled={isLoggingIn}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    viewBox="0 0 24 24"
                    width="20"
                  >
                    <path
                      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                      fill="#4285F4"
                    />
                    <path
                      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                      fill="#34A853"
                    />
                    <path
                      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                      fill="#EA4335"
                    />
                    <path d="M1 1h22v22H1z" fill="none" />
                  </svg>{' '}
                  Continue with Google
                </S.GoogleLoginButton>
              </S.LoginMethodsContainer>
              <T.Body textBlack style={{ opacity: 0.75, fontSize: '0.875em' }}>
                By continuing, you agree to the{' '}
                <Link style={{ color: '#a614ce' }} href="/terms">
                  Terms & Conditions
                </Link>{' '}
                and{' '}
                <Link style={{ color: '#a614ce' }} href="/privacy">
                  Privacy Policy
                </Link>
                .
              </T.Body>
            </>
          )}
        </S.StyledContainer>
      </Layout>
    </>
  );
};

export default Login;
